import { Icon } from '../generic-icon/Icon';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { withTheme } from '@emotion/react';
import React, { CSSProperties, FC } from 'react';
import { Theme, ThemeProps } from '@theme/base';
import { TestId } from '@components/test-ids';

export interface BadgeProps {
  label: string;
  tooltip: string;
  icon?: string;
  theme: Theme;
  containerStyle?: CSSProperties;
  renderIcon?: JSX.Element;
  testId?: string;
  onClick?: () => void;
}

const Container = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4.4rem',
  borderRadius: '2.2rem',
  boxShadow: '0px 0px 6px 0px rgb(25, 25, 25)',
  color: theme.custom.states.link.default,
  padding: theme.custom.spacing.medium,
  margin: theme.custom.spacing.xSmall,
  transition: `color ${theme.custom.transitions.default}`,
  [mq.small]: {
    margin: theme.custom.spacing.medium
  }
}));

const Label = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'flex',
  margin: theme.custom.spacing.xSmall,
  fontSize: '1.4rem'
}));

const iconStyles: (theme: Theme) => any = (theme: Theme) => ({
  color: theme.custom.colors.group10.base,
  fontSize: '2rem'
});

export const BadgeComponent: FC<BadgeProps> = (props: BadgeProps) => {
  return (
    <Container data-testid={props.testId || TestId.badge} style={props.containerStyle} onClick={props.onClick}>
      {props.renderIcon || <Icon name={props.icon || ''} css={iconStyles(props.theme)} />}
      <Label>{props.label}</Label>
    </Container>
  );
};

export const Badge = withTheme(BadgeComponent);

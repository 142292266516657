import useRipple from 'use-ripple-hook';
import { useTheme } from '@emotion/react';
import { Theme } from '@theme/base';

export const RippleCard = (props) => {
  const theme: Theme = useTheme();
  const { children, style, fixedHeight, ...otherProps } = props;

  const [ripple, event] = useRipple({
    color: `${theme.custom.colors.group10.dark}80`,
    disabled: otherProps.disabled
  });

  return (
    <div
      ref={ripple}
      onMouseDown={event}
      role="link"
      tabIndex={0}
      {...otherProps}
      style={{ borderRadius: 10, ...(!fixedHeight && { height: '100%' }), ...style }}
    >
      {children}
    </div>
  );
};

import { Icon } from '../../generic-icon/Icon';
import { FullScreenModal } from '../../modal/fullscreen/FullScreenModal';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { GalleryImage } from '@model/common/gallery-image';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { Icons } from '@model/common/icons';

export interface ImageGalleryModalProps {
  theme?: Theme;
  testId?: string;
  showGallery: boolean;
  galleryImages: Array<GalleryImage> | [];
  onDismiss: () => void;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const ImageGalleryContainer = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
  margin: '0 auto',
  alignItems: 'center',
  outline: 'none',
  transition: `max-width ${theme.custom.transitions.smooth}`
}));

const GalleryCloseButtonContainer = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  borderBottomLeftRadius: 6,
  padding: `${theme.custom.spacing.xLarge}px ${theme.custom.spacing.medium}px ${theme.custom.spacing.large}px ${theme.custom.spacing.medium}px`,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: ZIndex.CONTENT
}));

const GalleryCloseButton = styled.button(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  border: 'none',
  width: 40,
  marginRight: 2,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  zIndex: ZIndex.CONTENT,

  ['i']: {
    transition: `color ${theme.custom.transitions.default}`
  },

  ['&:hover']: {
    ['i']: {
      color: theme.custom.colors.group1.light
    }
  }
}));

const GalleryCloseIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '2.5rem',
  color: theme.custom.colors.group4.light
}));

const ImageGalleryInnerContainer = styled.div(({ theme }: ThemeProps) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
  padding: `${theme.custom.spacing.large}px ${theme.custom.spacing.medium}px`,
  width: '100%',
  scrollbarWidth: 'none',
  ['&::-webkit-scrollbar']: {
    display: 'none'
  }
}));

const ImageGalleryImage = styled.img(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.large,
  maxWidth: '100%',
  [mq.landscape]: {
    height: '100%',
    width: 'auto',
    ['&:last-child']: {
      paddingRight: theme.custom.spacing.large
    }
  },
  [mq.portrait]: {
    height: 'auto',
    width: '100%'
  }
}));

/* *************************** *
 *    ImageGalleryComponent    *
 * *************************** */

export const ImageGalleryComponent = (props: ImageGalleryModalProps) => {
  const { onDismiss, showGallery, galleryImages } = props;

  return (
    <FullScreenModal open={showGallery} onClose={onDismiss}>
      <ImageGalleryContainer data-testid={TestId.imageGallery.main}>
        <GalleryCloseButtonContainer>
          <GalleryCloseButton onClick={onDismiss} data-testid={TestId.imageGallery.closeButton}>
            <GalleryCloseIcon name={Icons.CLEAR} />
          </GalleryCloseButton>
        </GalleryCloseButtonContainer>
        <ImageGalleryInnerContainer>
          {galleryImages.map((image) => {
            return <ImageGalleryImage src={image.original} key={image.original} />;
          })}
        </ImageGalleryInnerContainer>
      </ImageGalleryContainer>
    </FullScreenModal>
  );
};

export const ImageGalleryModalMobile = withTheme(ImageGalleryComponent);

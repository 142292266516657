import styled from '@emotion/styled';
import { aspectRatioPaddings, AspectRatios, Image } from '@model/common/image';
import React, { useState } from 'react';
import { ThemeProps } from '@theme/base';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { ZIndex } from '@styles/z-index';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { textShadowMixins } from '@styles/mixins';
import { mq } from '@styles/breakpoints';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

export interface ImageCrossFadeProps {
  images: Array<Image>;
  isTile?: boolean;
}

const NAV_BUTTON_DIMENSION: number = 36;

const Container: any = styled.div(({ aspectRatio }: { aspectRatio: string }) => ({
  position: 'relative',
  width: '100%',
  height: 'auto',
  paddingTop: aspectRatioPaddings[aspectRatio]
}));

const NavButton = styled.div(({ theme }: ThemeProps) => ({
  display: 'none',
  height: NAV_BUTTON_DIMENSION,
  width: NAV_BUTTON_DIMENSION,
  position: 'absolute',
  top: `calc(50% - ${NAV_BUTTON_DIMENSION / 2}px)`,
  zIndex: ZIndex.CONTENT,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textShadow: textShadowMixins(theme.custom.colors.group2.base).light,
  i: {
    color: theme.custom.colors.white
  },
  [mq.medium]: {
    display: 'flex'
  }
}));

const LeftNav = styled(NavButton)(({ theme }: ThemeProps) => ({
  left: theme.custom.spacing.small
}));

const NavIcon = styled(Icon)({
  fontSize: '2rem'
});

const RightNav = styled(NavButton)(({ theme }: ThemeProps) => ({
  right: theme.custom.spacing.small
}));

const SlideshowImage: any = styled.div(({ url }: { url: string }) => ({
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  paddingTop: aspectRatioPaddings[AspectRatios['16:9']],
  userSelect: 'none',
  backgroundSize: 'cover',
  backgroundImage: `url(${url})`
}));

interface SlideshowContainerProps extends ThemeProps {
  isTile?: boolean;
}
const SlideshowContainer: any = styled.div(({ theme, isTile }: SlideshowContainerProps) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  ['> div']: {
    borderTopLeftRadius: isTile ? theme.custom.spacing.small : undefined,
    borderTopRightRadius: isTile ? theme.custom.spacing.small : undefined,
    transform: isTile ? 'translateZ(0)' : undefined
  }
}));

const Count: any = styled.div(({ theme }: ThemeProps) => ({
  borderTopLeftRadius: 4,
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: ZIndex.CONTENT,
  backgroundColor: theme.custom.colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.custom.spacing.xSmall}px ${theme.custom.spacing.small}px`,
  ...(theme.custom.typography.labels.default as any),
  minWidth: '7rem'
}));

const Camera = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.4rem',
  marginRight: theme.custom.spacing.xSmall,
  marginTop: 2
}));

const SpreadIndicator = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  bottom: theme.custom.spacing.large,
  borderRadius: '50%',
  position: 'absolute',
  height: 10,
  left: '50%',
  right: 0,
  transform: 'translateX(-50%)',
  width: 10,
  zIndex: ZIndex.CONTENT,
  boxShadow: `0px 1px 4px 0 ${theme.custom.colors.group10.dark},
    -14px 0px 0 -2px ${theme.custom.colors.white},
    -14px 1px 4px -2px ${theme.custom.colors.group10.dark},
    -25px 0px 0 -3px ${theme.custom.colors.white},
    -25px 1px 4px -3px ${theme.custom.colors.group10.dark},
		14px 0px 0 -2px ${theme.custom.colors.white},
    14px 1px 4px -2px ${theme.custom.colors.group10.dark},
    25px 0px 0 -3px ${theme.custom.colors.white},
    25px 1px 4px -3px ${theme.custom.colors.group10.dark}`,
  [mq.medium]: {
    display: 'none'
  }
}));

export const HeroGallery = (props: ImageCrossFadeProps) => {
  const { images, isTile } = props;
  const [index, setIndex] = useState(0);
  const imageSet: Array<string> = Array.from(new Set(images.map((image: Image) => image.url)));

  const handleOnPrevClick = (event: any) => {
    const next: number = index - 1;
    if (next < 0) {
      setIndex(imageSet.length - 1);
    } else {
      setIndex(next);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const handleOnNextClick = (event: any) => {
    const next: number = index + 1;
    if (next > imageSet.length - 1) {
      setIndex(0);
    } else {
      setIndex(next);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  function handleChangeIndex(index: number) {
    if (index > imageSet.length - 1) {
      setIndex(0);
    } else if (index < 0) {
      setIndex(imageSet.length - 1);
    } else {
      setIndex(index);
    }
  }

  function slideRenderer({ index }: any) {
    return <SlideshowImage key={`${imageSet[index]}-${index}`} url={imageSet[index]} />;
  }
  return (
    <Container aspectRatio={AspectRatios['16:9']}>
      <LeftNav onClick={handleOnPrevClick}>
        <NavIcon name={Icons.CHEVRON_LEFT} />
      </LeftNav>
      <RightNav onClick={handleOnNextClick}>
        <NavIcon name={Icons.CHEVRON_RIGHT} />
      </RightNav>
      <SpreadIndicator />
      <Count>
        <Camera name={Icons.PHOTO} />
        <div>
          {index + 1}/{imageSet.length}
        </div>
      </Count>
      <SlideshowContainer isTile={isTile}>
        <VirtualizeSwipeableViews
          axis={'x'}
          index={index}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
          slideRenderer={slideRenderer}
          slideCount={imageSet.length}
          overscanSlideAfter={1}
          suppressHydrationWarning
        />
      </SlideshowContainer>
    </Container>
  );
};

import React from 'react';
import { BaseModal } from '@components/common/modal';
import styled from '@emotion/styled';
import { Icon } from '@components/common/generic-icon';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';
import { textShadowMixins } from '@styles/mixins';
import { Icons } from '@model/common/icons';

export interface HeroModalProps {
  open: boolean;
  onClose: () => void;
  children?: any;
  headerImage?: null;
  testId?: string;
}

const HeadingContainer = styled.div();

const HeadingImage = styled.img({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: '100%',
  width: '100%'
});

const ModalOuterContainer = styled.div({
  height: '100%',
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start'
});

const ModalInnerContainer = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  position: 'relative',
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  overflow: 'hidden'
}));

const BodyContainer = styled.div(() => ({}));

const CloseIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  cursor: 'pointer',
  fontSize: '2rem',
  top: 0,
  right: 0,
  position: 'absolute',
  padding: theme.custom.spacing.large,
  color: theme.custom.colors.black,
  textShadow: textShadowMixins(theme.custom.colors.black).default
}));

export const FullScreenModal = ({ open, children, onClose, headerImage, testId }: HeroModalProps) => {
  return (
    <BaseModal open={open} hideCloseButton noBackdropDismiss>
      <ModalOuterContainer data-testid={testId || TestId.modal.fullscreen}>
        <ModalInnerContainer>
          {headerImage && (
            <HeadingContainer>
              <HeadingImage src={headerImage} />
              <CloseIcon name={Icons.CLEAR} onClick={onClose} />
            </HeadingContainer>
          )}
          <BodyContainer>{children}</BodyContainer>
        </ModalInnerContainer>
      </ModalOuterContainer>
    </BaseModal>
  );
};

import React, { useState, ReactElement } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { breakpoints } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { Image } from '@model/common';
import { Badge } from '@components/common/badge';
import { Icon } from '@components/common/generic-icon';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { getImageUrl } from '@util/images';
import { ImageGalleryModal } from './ImageGalleryModal';

export interface ImageGalleryModalControllerProps {
  gallery: Array<Image>;
  children?: ReactElement | Array<ReactElement>;
  labelKey?: string;
  tooltipKey?: string;
}

const Wrapper = styled.div({
  display: 'flex',
  position: 'relative',
  width: '100%'
});

const BottomContainer = styled.div(({ hasChildren }: { hasChildren: boolean }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  ...(hasChildren && {
    position: 'absolute',
    bottom: 0,
    left: 0
  })
}));

const GalleryBadge = styled.div(({ theme }: ThemeProps) => ({
  ['i, div']: {
    transition: `color ${theme.custom.transitions.default}`
  },
  ['&:hover']: {
    ['i, div']: {
      color: theme.custom.colors.group1.darker
    }
  },
  zIndex: ZIndex.CONTENT,
  cursor: 'pointer'
}));

const PhotoIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '2rem',
  color: theme.custom.states.link.default
}));

export const ImageGalleryModalController = ({
  gallery,
  children,
  labelKey,
  tooltipKey
}: ImageGalleryModalControllerProps) => {
  const theme: Theme = useTheme();
  const t: any = useI18NextContext();
  const [showGallery, setShowGallery] = useState<boolean>(false);

  const galleryImages = gallery!.map((image: Image) => ({
    thumbnail: getImageUrl(image.url, breakpoints.small),
    original: getImageUrl(image.url, breakpoints.medium)
  }));

  const toggleGallery = (show?: boolean) => {
    setShowGallery(typeof show !== 'undefined' ? show : !showGallery);
  };

  return (
    <>
      <Wrapper onClick={() => toggleGallery(true)}>
        {children && <div></div>}
        {children}
        <BottomContainer hasChildren={!!children}>
          <GalleryBadge>
            <Badge
              testId={TestId.productHero.photoGalleryBadge}
              containerStyle={{ backgroundColor: theme.custom.colors.white }}
              renderIcon={<PhotoIcon name={'mhi-photo'} />}
              label={`${t(labelKey || 'product__hero--gallery-badge-photos', {
                images: gallery.length
              })}`}
              tooltip={t(tooltipKey || 'product__hero--gallery-badge-tooltip')}
            />
          </GalleryBadge>
        </BottomContainer>
      </Wrapper>
      <ImageGalleryModal
        galleryImages={galleryImages}
        onDismiss={() => {
          setShowGallery(false);
        }}
        showGallery={showGallery}
      />
    </>
  );
};

import styled from '@emotion/styled';
import { breakpoints } from '@styles/breakpoints';
import { Image } from '@model/common';
import { HeroGallery } from '@components/common/hero-gallery';
import { ImageGalleryModalController } from '@components/common/image-gallery/modal/ImageGalleryModalController';
import { RippleCard } from '@components/common/ripple-card';
import { useResize } from '@components/hooks';
import { getImageUrl } from '@util/images';

export interface SplitGalleryProps {
  gallery: Array<Image>;
}

const GalleryContainer = styled.div({
  borderRadius: 10,
  overflow: 'hidden',
  position: 'relative'
});

const SplitGalleryContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gridColumnGap: 10,
  gridRowGap: 10,
  minHeight: 100
});

const SplitGalleryMain = styled(RippleCard)({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRowStart: 1,
  gridRowEnd: 3,
  position: 'relative'
});

const SplitGalleryAbove = styled(RippleCard)({
  gridColumn: 3,
  gridRow: 1,
  position: 'relative'
});

const SplitGalleryBelow = styled(RippleCard)({
  gridColumn: 3,
  gridRow: 2,
  position: 'relative'
});

const SplitImg = styled.img({
  borderRadius: 10,
  cursor: 'pointer',
  height: '100%',
  width: '100%'
});

export const SplitGallery = ({ gallery }: SplitGalleryProps) => {
  const [_, mobile] = useResize();
  const getGalleryImage = (img: Image) => getImageUrl(img.url, breakpoints.small);

  return (
    <GalleryContainer>
      {mobile && <HeroGallery images={gallery} />}
      {!mobile && (
        <>
          <ImageGalleryModalController gallery={gallery} labelKey={'product__hero--gallery-badge-view-photos'}>
            <SplitGalleryContainer>
              <SplitGalleryMain>
                <SplitImg src={getGalleryImage(gallery[0])} loading={'lazy'} />
              </SplitGalleryMain>
              <SplitGalleryAbove>
                <SplitImg src={getGalleryImage(gallery[1])} loading={'lazy'} />
              </SplitGalleryAbove>
              <SplitGalleryBelow>
                <SplitImg src={getGalleryImage(gallery[2])} loading={'lazy'} />
              </SplitGalleryBelow>
            </SplitGalleryContainer>
          </ImageGalleryModalController>
        </>
      )}
    </GalleryContainer>
  );
};

import { Icon } from '../../generic-icon/Icon';
import { BaseModal } from '../../modal/base/BaseModal';
import styled from '@emotion/styled';
import { aspectRatioPaddings, AspectRatios } from '@model/common/image';
import { breakpoints, mq } from '@styles/breakpoints';
import { mixins } from '@styles/mixins';
import { withTheme } from '@emotion/react';
import React from 'react';
import * as ReactImageGallery from 'react-image-gallery';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { GalleryImage } from '@model/common/gallery-image';
import { Icons } from '@model/common/icons';
import { ZIndex } from '@styles/z-index';

export interface ImageGalleryModalProps {
  theme?: Theme;
  testId?: string;
  showGallery: boolean;
  galleryImages: Array<GalleryImage> | [];
  onDismiss: () => void;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const ImageGalleryContainer = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  maxWidth: 500,
  margin: '0 auto',
  alignItems: 'center',
  outline: 'none',
  transition: `max-width ${theme.custom.transitions.smooth}`,

  ['@media (min-height: 760px)']: {
    maxWidth: 800
  },

  ['@media (min-height: 890px)']: {
    maxWidth: breakpoints.medium
  },

  ['.image-gallery']: {
    width: '100%'
  },

  ['.image-gallery-slides']: {
    position: 'relative',
    width: '100%',
    paddingTop: aspectRatioPaddings[AspectRatios['Gallery']],
    display: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },

  ['.image-gallery-slide.center']: {
    position: 'absolute'
  },

  ['.image-gallery-slide']: {
    height: '100%',
    background: 'transparent',
    ['> div']: {
      height: 'inherit'
    }
  },

  ['.image-gallery-bullet  ']: {
    borderWidth: 2,
    ...mixins(theme).defaultDropShadow.boxShadow
  },

  ['.image-gallery-icon']: {
    svg: {
      height: 60
    }
  },

  /*
    left/ right as negative value of the viewport width - container width divided by four
    puts it in the center of the whitespace to the left/ right of the image
  */
  ['.image-gallery-left-nav']: {
    left: 'calc((100vw - 100%)/-4)',
    transform: 'translate(-50%, -50%)'
  },

  ['.image-gallery-right-nav']: {
    right: 'calc((100vw - 100%)/-4)',
    transform: 'translate(50%, -50%)'
  },

  ['.image-gallery-right-nav, .image-gallery-left-nav']: {
    color: theme.custom.colors.group4.light,
    filter: 'none',
    ['&:hover']: {
      ['&::before']: {
        color: theme.custom.colors.group1.light
      }
    }
  },

  ['.image-gallery-image']: {
    lineHeight: 0,
    height: '100%',
    textAlign: 'center',

    ['img']: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',

      [mq.ie()]: {
        height: 'auto'
      }
    }
  }
}));

const GalleryCloseButtonContainer = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  right: theme.custom.spacing.xxLarge,
  top: theme.custom.spacing.xxLarge,
  zIndex: ZIndex.CONTENT
}));

const GalleryCloseButton = styled.button(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  border: 'none',
  width: 40,
  height: 40,
  marginRight: 2,
  backgroundColor: 'transparent',
  cursor: 'pointer',

  ['i']: {
    transition: `color ${theme.custom.transitions.default}`
  },

  ['&:hover']: {
    ['i']: {
      color: theme.custom.colors.group1.light
    }
  },

  ['@media (min-width: 1024px)']: {
    padding: 0
  }
}));

const GalleryCloseIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '2.5rem',
  color: theme.custom.colors.group4.light
}));

/* *************************** *
 *    ImageGalleryComponent    *
 * *************************** */

export const ImageGalleryComponent = (props: ImageGalleryModalProps) => {
  const { onDismiss, theme, showGallery, galleryImages } = props;

  return (
    <BaseModal open={showGallery} hideCloseButton onDismiss={onDismiss} bgColor={theme?.custom.colors.white}>
      <ImageGalleryContainer data-testid={TestId.imageGallery.main}>
        <GalleryCloseButtonContainer>
          <GalleryCloseButton onClick={onDismiss} data-testid={TestId.imageGallery.closeButton}>
            <GalleryCloseIcon name={Icons.CLEAR} />
          </GalleryCloseButton>
        </GalleryCloseButtonContainer>
        <ReactImageGallery.default
          items={galleryImages}
          showFullscreenButton={false}
          showBullets={false}
          showThumbnails={false}
          showPlayButton={false}
        />
      </ImageGalleryContainer>
    </BaseModal>
  );
};

export const ImageGalleryModal = withTheme(ImageGalleryComponent);
